<template lang="pug">
BasePane
  Accordion(fix)
    .user-info-message.alert.alert-danger.mb-0(v-if="needButton") {{ $t('pickAPresent.noButton') }}
    PaneLayout#present-count-layout.mt-3(gap="4px" layout="col")
      template(#left) {{ $t('pickAPresent.presentCount') }}
      template(#right)
        OmInput#present-count(small v-model.number="presentCount" type="number" :min="2" :max="5")
    RangeInput(
      :label="$t('pickAPresent.presentSize')"
      :min="20"
      :max="200"
      :step="1"
      editMobile
      v-model="presentSize"
    )
    RangeInput(:label="$t('spacing')" :step="1" editMobile v-model="presentSpacing")
    OmButton#coupon-settings(@click="showSettings" primary block small) {{ $t('pickAPresent.settings.button') }}
    OmButton#coupon-settings(@click="showTest" primary block small) {{ $t('pickAPresent.test.button') }}
  hr.my-3
  Accordion
    template(#title)
      span {{ $t('title') }}
    template(#toggle)
      OmSwitch#title-toggle(v-model="isTitleActive")
    template
      OmInput#title-text(:label="$t('text')" v-model="titleText" small)
      FontFamily(v-model="titleFont" layout="row" manage)
      OmColorInput(
        :label="$t('fontColor')"
        typeOfComponent="pickapresent"
        layout="col"
        property="title.color"
        dsAllowed
      )
      Dropdown#line-height(
        :items="lineHeightItems"
        :label="$t('lineHeight')"
        layout="col"
        v-model="titleLineHeight"
      )
      Dropdown#font-weight(
        :items="fontWeightItems"
        :label="$t('weight')"
        layout="col"
        v-model="titleFontWeight"
      )
      RangeInput(:label="$t('fontSize')" :min="8" :max="90" :step="1" v-model="titleFontSize")
      FontStyle(
        :label="$t('style')"
        :fontItalic.sync="titleFontItalic"
        :textDecoration.sync="titleTextDecoration"
      )
      Align(textAlign :labelOverride="$t('textAlign')" v-model="titleTextAlign")
      Margin(customProperty="$device.title.margin")
      Padding(customProperty="$device.title.padding")
  hr.mb-1
  Accordion
    template(#title)
      span {{ $t('pickAPresent.subtitle') }}
    template(#toggle)
      OmSwitch#title-toggle(v-model="isSubtitleActive")
    template
      OmInput#title-text(:label="$t('text')" v-model="subTitleText" small)
      FontFamily(v-model="subTitleFont" layout="row" manage)
      OmColorInput(
        :label="$t('fontColor')"
        typeOfComponent="pickapresent"
        layout="col"
        property="subtitle.color"
        dsAllowed
      )
      Dropdown#line-height(
        :items="lineHeightItems"
        :label="$t('lineHeight')"
        layout="col"
        v-model="subTitleLineHeight"
      )
      Dropdown#font-weight(
        :items="fontWeightItems"
        :label="$t('weight')"
        layout="col"
        v-model="subTitleFontWeight"
      )
      RangeInput(:label="$t('fontSize')" :min="8" :max="90" :step="1" v-model="subTitleFontSize")
      FontStyle(
        :label="$t('style')"
        :fontItalic.sync="subTitleFontItalic"
        :textDecoration.sync="subTitleTextDecoration"
      )
      Align(textAlign :labelOverride="$t('textAlign')" v-model="subTitleTextAlign")
      Margin(customProperty="$device.subtitle.margin")
      Padding(customProperty="$device.subtitle.padding")
  hr.mb-1
  Accordion(fix)
    template(#title)
      .font-weight-bold {{ $t('spacing') }}
    Margin
  hr.my-3
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>
<script>
  import { mapMutations, mapState } from 'vuex';
  import { fontWeightOptions, lineHeightOptions } from '@/editor/quill_options';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import FontStyle from '@/components/Editor/FontStyle/FontStyle.vue';
  import FontFamily from '@/components/Editor/Controls/FontFamily.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      Accordion,
      PaneLayout,
      RangeInput,
      FontStyle,
      FontFamily,
      Dropdown,
      Align,
      Margin,
      Padding,
      Hide,
      BasePane,
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      ...mapState(['mobilePreview', 'validationError']),
      needButton() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('pickAPresentNoButton')
        );
      },
      presentCount: {
        get() {
          return Number(this.getValueOf('data.presentCount'));
        },
        set(value) {
          if (value > 5) {
            value = 5;
          }

          if (value !== '' && value < 2) {
            value = 2;
          }

          this.setValueOf('data.presentCount', Number(value));
        },
      },
      presentSize: {
        get() {
          const mobileValue = this.getValueOf('mobile.icon.size');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.icon.size'));
          }
          return Number(this.getValueOf('$device.icon.size'));
        },
        set(value) {
          this.setValueOf('$device.icon.size', value);
        },
      },
      presentSpacing: {
        get() {
          const mobileValue = this.getValueOf('mobile.icon.horizontalSpacing');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.icon.horizontalSpacing'));
          }
          return Number(this.getValueOf('$device.icon.horizontalSpacing'));
        },
        set(value) {
          this.setValueOf('$device.icon.horizontalSpacing', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.getValueOf('mobile.hidden');
        },
        set(v) {
          this.setValueOf('mobile.hidden', v);
        },
      },
      isTitleActive: {
        get() {
          return this.getValueOf('data.title.active');
        },
        set(v) {
          this.setValueOf('data.title.active', v);
        },
      },
      titleText: {
        get() {
          return this.getValueOf('data.title.text');
        },
        set(v) {
          this.setValueOf('data.title.text', v);
        },
      },
      subTitleText: {
        get() {
          return this.getValueOf('data.subtitle.text');
        },
        set(v) {
          this.setValueOf('data.subtitle.text', v);
        },
      },
      titleFont: {
        get() {
          return this.getValueOf('desktop.title.fontFamily');
        },
        set(v) {
          this.setValueOf('desktop.title.fontFamily', v);
        },
      },
      subTitleFont: {
        get() {
          return this.getValueOf('desktop.subtitle.fontFamily');
        },
        set(v) {
          this.setValueOf('desktop.subtitle.fontFamily', v);
        },
      },
      titleLineHeight: {
        get() {
          return Number(this.getValueOf('desktop.title.lineHeight'));
        },
        set(v) {
          this.setValueOf('desktop.title.lineHeight', v);
        },
      },
      subTitleLineHeight: {
        get() {
          return Number(this.getValueOf('desktop.subtitle.lineHeight'));
        },
        set(v) {
          this.setValueOf('desktop.subtitle.lineHeight', v);
        },
      },
      titleFontWeight: {
        get() {
          return Number(this.getValueOf('desktop.title.fontWeight'));
        },
        set(v) {
          this.setValueOf('desktop.title.fontWeight', v);
        },
      },
      subTitleFontWeight: {
        get() {
          return Number(this.getValueOf('desktop.subtitle.fontWeight'));
        },
        set(v) {
          this.setValueOf('desktop.subtitle.fontWeight', v);
        },
      },
      titleTextAlign: {
        get() {
          return this.getValueOf('desktop.title.textAlign');
        },
        set(v) {
          this.setValueOf('desktop.title.textAlign', v);
        },
      },
      subTitleTextAlign: {
        get() {
          return this.getValueOf('desktop.subtitle.textAlign');
        },
        set(v) {
          this.setValueOf('desktop.subtitle.textAlign', v);
        },
      },
      titleFontSize: {
        get() {
          return Number(this.getValueOf('desktop.title.fontSize'));
        },
        set(v) {
          this.setValueOf('desktop.title.fontSize', v);
        },
      },
      subTitleFontSize: {
        get() {
          return Number(this.getValueOf('desktop.subtitle.fontSize'));
        },
        set(v) {
          this.setValueOf('desktop.subtitle.fontSize', v);
        },
      },
      titleFontItalic: {
        get() {
          return this.getValueOf('desktop.title.fontItalic');
        },
        set(v) {
          this.setValueOf('desktop.title.fontItalic', v);
        },
      },
      subTitleFontItalic: {
        get() {
          return this.getValueOf('desktop.subtitle.fontItalic');
        },
        set(v) {
          this.setValueOf('desktop.subtitle.fontItalic', v);
        },
      },
      titleTextDecoration: {
        get() {
          return this.getValueOf('desktop.title.textDecoration');
        },
        set(v) {
          this.setValueOf('desktop.title.textDecoration', v);
        },
      },
      subTitleTextDecoration: {
        get() {
          return this.getValueOf('desktop.subtitle.textDecoration');
        },
        set(v) {
          this.setValueOf('desktop.subtitle.textDecoration', v);
        },
      },
      isSubtitleActive: {
        get() {
          return this.getValueOf('data.subtitle.active');
        },
        set(v) {
          this.setValueOf('data.subtitle.active', v);
        },
      },
      fontWeightItems() {
        return fontWeightOptions.map((fw) => ({
          text: fw.label,
          value: parseInt(fw.value, 10),
        }));
      },
      lineHeightItems() {
        return lineHeightOptions.map((l) => ({
          text: l.label,
          value: l.value,
        }));
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      showSettings() {
        this.changeFormManagerVisibility({ show: 'pickAPresentSettings' });
      },
      showTest() {
        this.changeFormManagerVisibility({ show: 'pickAPresentTest' });
      },
    },
  };
</script>
